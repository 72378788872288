<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-card
    tile
  >
    <v-app-bar
      color="primary"
      dark
      height="55"
    >
      <v-toolbar-title>
        {{ $t('Pago Realizado') }}
      </v-toolbar-title>
      <slot></slot>
    </v-app-bar>
  </v-card>

      <v-card
        flat
        outlined
        class="mt-2"
      >
        <v-container fluid>
          <v-card class="elevation-0 text-center">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">
                  <v-btn class="ma-2" color="primary" fab outlined dark>
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </div>
                <v-list-item-title class="headline mb-1">
                  {{ $t('Su pago fue procesado') }}.
                </v-list-item-title>
                <v-list-item-subtitle v-if="request">
                  {{ $t('Un cargo por') }}: {{ request.amount|currency }}
                  {{ $t('de fecha') }}: {{ request.date|date }}
                  {{ $t('fue realizado a su tarjeta') }}.
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="response" class="pt-2">
                  {{ $t('El número de autorización es') }}: {{ response.authorization_code }}.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import types from '@/types';
// import appMixin from '@/mixins/app';

export default {
//   mixins: [appMixin],

  data() {
    return {
      title: 'Payment Done',
    };
  },

  computed: {
    ...mapState(`${types.PAYMENT}`, [
      'payment',
      'paymentDetail',
    ]),
    ...mapState(`${types.REGISTER_INVOICES}`, [
      'registerInvoices',
    ]),
    ...mapGetters(`${types.PAYMENT}`, [
      'request',
      'response',
    ]),
    ...mapGetters(`${types.REGISTER_INVOICES}`, [
      'selectedInvoices',
    ]),
  },

  methods: {
    ...mapActions({
      [types.GET_PAYMENT_ATTEMPT]: `${types.PAYMENT}/${types.GET_PAYMENT_ATTEMPT}`,
    }),

    enrollmentStudent() {
      if (this.selectedInvoices.length > 0 && this.payment.advancepayments.length > 0) {
        const AUTORIZACION_PAGO = this.paymentDetail.AuthorizationCode;

        const INSCRIPCION = this.selectedInvoices.map((invoice) => {
          const planSeleccionado = invoice.PLAN.filter(
            (planes) => planes.IDPLAN === invoice.selected_payment_plan,
          );
          const cuotasSelecionadas = invoice.fee.map((f) => f.value);
          planSeleccionado[0].CUOTAS.map((cuota) => {
            if (cuotasSelecionadas.includes(cuota.NO_CUOTA)) {
              const cuotaSl = cuota;
              cuotaSl.PAGO_OBL_INSC = 'X';
              return cuotaSl;
            }
            return cuota;
          });
          return {
            CENTRO: invoice.CENTRO,
            ANNOESC: invoice.ANNOESC,
            GRADOESC: invoice.GRADOESC,
            KUNNR: invoice.KUNNR,
            PERNR: invoice.PERNR,
            RESPONSABLE_PAGO: invoice.RESPONSABLE_PAGO,
            idinterlocutor: invoice.IDINTERLOCUTOR,
            nui: invoice.NUI,
            nombres_estud: invoice.NOMBRES_ESTUD,
            nombre_centro: invoice.NOMBRE_CENTRO,
            email: this.resident.email,
            BUKRS: invoice.BUKRS,
            IDCONCEPTO: invoice.IDCONCEPTO,
            DESCRIPCION_CES: invoice.DESCRIPCION_CES,
            DESCRIPCION_CEN: invoice.DESCRIPCION_CEN,
            AUTORIZACION_PAGO,
            PLAN: planSeleccionado,
          };
        });

        const paymentData = {
          KUNNR: this.currentClient.sap_customer,
          INSCRIPCION,
        };
        this[types.MAKE_REGISTER_INVOICES_PAYMENT]({ paymentData });
      }
    },
  },

  mounted() {
    this[types.GET_PAYMENT_ATTEMPT](this.$route.params.id).then(() => {
    //   this.enrollmentStudent();
    });
  },
};
</script>
