import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"tile":""}},[_c(VAppBar,{attrs:{"color":"primary","dark":"","height":"55"}},[_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.$t('Pago Realizado'))+" ")]),_vm._t("default")],2)],1),_c(VCard,{staticClass:"mt-2",attrs:{"flat":"","outlined":""}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"elevation-0 text-center"},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c('div',{staticClass:"overline mb-4"},[_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary","fab":"","outlined":"","dark":""}},[_c(VIcon,[_vm._v("mdi-check")])],1)],1),_c(VListItemTitle,{staticClass:"headline mb-1"},[_vm._v(" "+_vm._s(_vm.$t('Su pago fue procesado'))+". ")]),(_vm.request)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.$t('Un cargo por'))+": "+_vm._s(_vm._f("currency")(_vm.request.amount))+" "+_vm._s(_vm.$t('de fecha'))+": "+_vm._s(_vm._f("date")(_vm.request.date))+" "+_vm._s(_vm.$t('fue realizado a su tarjeta'))+". ")]):_vm._e(),(_vm.response)?_c(VListItemSubtitle,{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm.$t('El número de autorización es'))+": "+_vm._s(_vm.response.authorization_code)+". ")]):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }